import React, { useEffect } from 'react';
import styles from './index.module.css';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { LogoWhite32 } from '../../../images';
import { DiLinux } from 'react-icons/di';
import { BsWindows, BsApple, BsCheck2, BsAndroid2 } from 'react-icons/bs';
// import { onTestConnect } from '../../../serverless/test';
import { useApplicationContext } from '../../../context/application';

const Home = () => {

    const navigate = useNavigate();
    const { isLogged } = useApplicationContext();

    // const [state, setState] = useState({ dataTest: `Buscando..` });
    // let { dataTest } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = async () => {
        // dataTest = await onTestConnect();
        // setState({ dataTest });
        console.log(isLogged)
        if (isLogged) {
            navigate('panel', { replace: true });
        }
    }

    return (
        <div className={styles.container} >

            <div className={styles.header} >
                <Typography className={styles.headerTitle} > SynTecn </Typography>
                <Typography className={styles.headerSubTitle} > Sistemas, soluções e inovações </Typography>
                <img src={LogoWhite32} alt="Logo" />
                {/* <DiCodeigniter color='white' size={30} /> */}
                <Button
                    className={styles.headerAcess}
                    variant='outlined'
                    onClick={() => navigate(isLogged ? 'panel' : 'login', { replace: true })} > Acesso ao Painel </Button>
            </div>

            <div className={styles.platforms} >
                <BsWindows color='royalblue' size={40} title='Disponível para Windows' />
                <BsApple color='royalblue' size={40} title='Disponível para Apple' />
                <DiLinux color='royalblue' size={40} title='Disponível para Linux' />
                <BsAndroid2 color='royalblue' size={40} title='Disponível para Android' />
            </div>

            <div className={styles.boxList} >

                <div className={styles.listLeft} >
                    <Typography> Controle de Produtos <BsCheck2 color='seagreen' size={30} style={{ marginLeft: 10 }} /> </Typography>
                    <Typography> Controle de Vendas <BsCheck2 color='seagreen' size={30} style={{ marginLeft: 10 }} /> </Typography>
                    <Typography> Controle de Serviços <BsCheck2 color='seagreen' size={30} style={{ marginLeft: 10 }} /> </Typography>
                    <Typography> Controle de Contas <BsCheck2 color='seagreen' size={30} style={{ marginLeft: 10 }} /> </Typography>
                    <Typography> Controle de Relatórios <BsCheck2 color='seagreen' size={30} style={{ marginLeft: 10 }} /> </Typography>
                </div>

                <div className={styles.listRight} >
                    <Typography> Disponível na Web <BsCheck2 color='seagreen' size={30} style={{ marginLeft: 10 }} /> </Typography>
                    <Typography> Disponível no Mobile <BsCheck2 color='seagreen' size={30} style={{ marginLeft: 10 }} /> </Typography>
                    <Typography> Disponível para Windows <BsCheck2 color='seagreen' size={30} style={{ marginLeft: 10 }} /> </Typography>
                    <Typography> Disponível para IOS <BsCheck2 color='seagreen' size={30} style={{ marginLeft: 10 }} /> </Typography>
                    <Typography> Disponível para Linux <BsCheck2 color='seagreen' size={30} style={{ marginLeft: 10 }} /> </Typography>
                </div>

            </div>

            {/* <Typography> API SSL: {dataTest} </Typography> */}

            {/* <Button onClick={() => navigate('login')} >Login</Button>
            <Button onClick={() => navigate('panel')} >Painel</Button> */}
        </div>
    )
}

export default Home;