import Dexie from "dexie";

const local_db = new Dexie('local_db');

local_db.version(1).stores({
    products: `++id`
});

// local_db?.products.add({ });

// local_db.open().then(function (local_db) {
//     // Database opened successfully
//     console.log("db local open")
// }).catch (function (err) {
//     // Error occurred
//     console.log("Erro db local to open")
// });

export {local_db};
// Dexie.exists('local_db').then((exist) => {
    
//     if (!exist) {   
//     }
// });


