import { BrowserRouter } from "react-router-dom";
import ApplicationProvider from "./context/application";
import RouterWeb from './web/Router';
// import RouterMobile from './mobile/Router';
// import { isMobile } from 'react-device-detect';

const Router = () => {
    return (
        <BrowserRouter>
            <ApplicationProvider>
                {/* {isMobile ? <RouterMobile /> : <RouterWeb />} */}
                <RouterWeb />
            </ApplicationProvider>
        </BrowserRouter>
    )
}

export default Router;